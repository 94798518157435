import { useState, useEffect } from 'react'
import Card from '@mui/material/Card'
import CardMedia from '@mui/material/CardMedia'
import { Box } from '@mui/system'

function Quality20Clip({ clipUrl, clipId, onClipClick, marked, currentClipIndex, clipIndex }) {
    const [borderColor, setBorderColor] = useState(getMarkColor(marked))
    const [currentColor, setCurrentColor] = useState(currentClipIndex === clipIndex ? '#3874CB' : 'transparent')

    const handleCardClick = () => {
        onClipClick(clipId)
    }

    function getMarkColor(mark) {
        if (mark == null) return 'transparent'
        if (mark === 'true') return 'yellow'
        if (mark === 'false') return '#be43f7'
        return '#2bedd6'
    }

    // maybe use a state for is selected here and report up what happened.
    useEffect(() => {
        setBorderColor(getMarkColor(marked))
    }, [marked])

    useEffect(() => {
        setCurrentColor(currentClipIndex === clipIndex ? '#3874CB' : 'transparent')
    }, [currentClipIndex])

    return (
        <Box height="175px" width="250px">
            <Card onClick={handleCardClick} sx={{ border: `solid ${currentColor} 6px` }}>
                <Card onClick={handleCardClick} sx={{ border: `solid ${borderColor} 6px` }}>
                    <CardMedia component="img" image={clipUrl} id={clipId} />
                </Card>
            </Card>
        </Box>
    )
}

export default Quality20Clip
