import { BrowserRouter, Route, Routes } from 'react-router-dom'
import Labeling from '../pages/Labeling'
import LabelingCardiologistPage from '../pages/LabelingCardiologistPage'
import Welcome from './Welcome'
import QualityPage from '../pages/QualityPage'
import LabelingQuality20Page from '../pages/LabelingQuality20Page'

export default function AppRoutes() {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Welcome headline="Welcome" />} />
                <Route path="/quality-compare" element={<QualityPage qualityType={'compare'} />} />
                <Route path="/quality-diagnosable" element={<QualityPage qualityType={'diagnosable'} />} />
                <Route path="/labeling-sonographer" element={<Labeling />} />
                <Route path="/labeling-cardiologist" element={<LabelingCardiologistPage />} />
                <Route path="/labeling-quality20" element={<LabelingQuality20Page />} />
                <Route path="*" element={<Welcome headline="Page Not Found" />} />
            </Routes>
        </BrowserRouter>
    )
}
