import { useState, useEffect, useContext, useRef } from 'react'
import { Grid, Box, Container, Button, CircularProgress } from '@mui/material'
import useFetch from '../hooks/useFetch'
import postDataHandler from '../handlers/postDataHandler'
import { Context } from '../App'
import Quality20Clips from '../components/quality20/Quality20Clips'
import { viewQuestions } from '../data/quality20-questions'
import { Quality20ExamsSection } from '../components/quality20/Quality20ExamsSection'
import { Quality20QuestionSection } from '../components/quality20/Quality20QuestionSection'
import { Quality20KeyboardShortcutsLegend } from '../components/quality20/Quality20KeyboardShortcutsLegend'

export default function LabelingQuality20Page() {
    const AppContext = useContext(Context)
    const [sequences, setSequences] = useState(null)
    const [pageStates, setPageStates] = useState({ newExamPage: null, currentExamPage: null, totalPages: null })
    const [selectedSequence, setSelectedSequence] = useState(null)
    const [isLoadingSelectedSequence, setIsLoadingSelectedSequence] = useState(false)
    const [sequenceAnswers, setSequenceAnswers] = useState({})
    const [currentQuestion, setCurrentQuestion] = useState(null)
    const [canSubmit, setCanSubmit] = useState(false)
    const [allDone, setAllDone] = useState(false)
    const [lowResolutionOn, setLowResolutionOn] = useState(false)
    const { fetchData: fetchSequences, data: sequencesResponse, setData: setSequencesResponse, isPending: isLoadingSequences, error: sequencesFetchError } = useFetch()
    const { fetchData: fetchSequenceData, data: sequenceData, setData: setSequenceData, isPending: isLoadingSequenceData, error: sequenceDataFetchError } = useFetch()
    const doneButton = useRef()

    function getSequencesPath() {
        if (pageStates.newExamPage === null) return `/api/quality20/sequences`
        return `/api/quality20/sequences?page=${pageStates.newExamPage}`
    }

    // fetch sequences
    useEffect(() => {
        if (!AppContext?.userEmail) {
            return
        }
        const abortController = new AbortController()
        const path = getSequencesPath()
        fetchSequences(path, abortController)
        return () => {
            abortController.abort()
        }
    }, [AppContext?.userEmail, pageStates?.newExamPage])

    // get sequences
    useEffect(() => {
        if (!sequencesResponse) return
        setSequences(sequencesResponse.sequences)
        setPageStates((prev) => ({ ...prev, currentExamPage: sequencesResponse.page, totalPages: sequencesResponse.totalPages }))
    }, [sequencesResponse])

    // get sequence data
    useEffect(() => {
        if (!selectedSequence) return
        // ## setLoadedClipsCounter(0)
        setIsLoadingSelectedSequence(true)
        const abortController = new AbortController()
        const path = `/api/quality20/exams/${selectedSequence.examId}/sequences/${selectedSequence.sequenceId}/data`
        fetchSequenceData(path, abortController)
        setSequenceAnswers({})
        return () => {
            abortController.abort()
        }
    }, [selectedSequence])

    useEffect(() => {
        if (sequenceData == null) return
        sequenceData?.clips && setIsLoadingSelectedSequence(false)
        setSequenceAnswers(sequenceData.labeling)
        selectCurrentQuestion(sequenceData.labeling)
    }, [sequenceData])

    useEffect(() => {
        setCanSubmit(allClipsMarkedForCurrentQuestion())
    }, [currentQuestion])

    useEffect(() => {
        setCanSubmit(allClipsMarkedForCurrentQuestion())
    }, [sequenceAnswers])

    // use the pre-load hook to preload the next sequences in the list
    // ## usePreLoad(loadedClipsCounter, sequences, selectedSequence, numOfClips, qualityType)

    function selectCurrentQuestion(answers) {
        const sequenceQuestions = viewQuestions[sequenceData.view]
        for (const question of sequenceQuestions) {
            if (!answers[question.name]) {
                setCurrentQuestion(question)
                return
            }
        }
        setCurrentQuestion(sequenceQuestions[0])
    }

    function submitAnswers() {
        const submitParams = submitAnswersParams()
        const path = `/api/quality20/exams/${sequenceData.examId}/sequences/${sequenceData.sequenceId}/save-answers` + submitParams
        async function postData() {
            const authHeaders = await AppContext?.getAuthHeaders()
            const response = await postDataHandler(path, authHeaders, sequenceAnswers)
            console.log(response)
            if (response.success) {
                
                // setSequenceData((prev) => ({ ...prev, labeling: sequenceAnswers }))
                if (allQuestionsAnswered(sequenceAnswers)) {
                    console.log("moving to the next sequence")
                    moveToNextSequenceOrExam()
                } else {
                    selectCurrentQuestion(sequenceAnswers)
                }
            } else if (response?.errorMessage) {
                AppContext?.showToast(response.errorMessage)
            }
        }
        postData()
    }

    function submitAnswersParams() {
        const sequenceDone = allQuestionsAnswered(sequenceAnswers)
        if (sequenceDone) markSequence('done')
        const examDone = sequences.every((item) => item.status === 'done') ? `&also_exam=${true}` : ``

        return (sequenceDone ? `?mark_seq_done=${true}` : `?mark_seq_done=${false}`) + examDone
    }

    const handleDone = () => {
        console.log('Done clicked')
        if (allClipsMarkedForCurrentQuestion() && sequenceData.sequenceId !== null) {
            submitAnswers(sequenceAnswers)
        } else {
            AppContext?.showToast('Please mark all clips')
        }
    }

    function moveToNextSequenceOrExam() {
        const currentSequenceIndex = sequences?.indexOf(selectedSequence)
        if (currentSequenceIndex + 1 < sequences.length) {
            // move to next sequence
            setSelectedSequence(sequences[currentSequenceIndex + 1])
        } else if (pageStates.currentExamPage + 1 < pageStates.totalPages) {
            //move to next exam
            setPageStates((prev) => ({ ...prev, newExamPage: prev.currentExamPage + 1 }))
        } else {
            setAllDone(true)
        }
    }

    function markSequence(newStatus) {
        if (sequences === null || sequences.length === 0 || selectedSequence === null) {
            return
        }
        const sequencesCopy = [...sequences]
        const sequenceIndex = sequences.indexOf(selectedSequence)
        sequencesCopy[sequenceIndex].status = newStatus
        setSequences(sequencesCopy)
    }

    function allClipsMarkedForQuestion(question) {
        if (sequences === null || sequences.length === 0 || selectedSequence === null || sequenceData == null) {
            return false
        }
        const allClipIds = Object.keys(sequenceData.clips)
        for (const clipId of allClipIds) {
            if (sequenceAnswers[currentQuestion.name]?.[clipId] == null) return false
        }
        return true
    }

    function allClipsMarkedForCurrentQuestion() {
        return allClipsMarkedForQuestion(currentQuestion)
    }

    function allQuestionsAnswered(answers) {
        const sequenceQuestions = viewQuestions[sequenceData.view]
        for (const question of sequenceQuestions) {
            if (!answers[question.name]) {
                return false
            }
        }
        return true
    }

    function changeLowResolution() {
        setLowResolutionOn(!lowResolutionOn)
    }

    return (
        <>
            {!sequences ? (
                <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
                    <CircularProgress />
                </Box>
            ) : (
                <>
                    <Container style={{ display: 'none' }} width="100%" sx={{ mb: '10px' }}>
                        <Grid container spacing={2}>
                            <Quality20ExamsSection
                                isLoadingExams={isLoadingSequences}
                                exams={sequences}
                                setSelectedExam={setSelectedSequence}
                                selectedExam={selectedSequence}
                                pageStates={pageStates}
                                setPageStates={setPageStates}
                            />
                        </Grid>
                    </Container>
                    <div style={{ display: 'flex', marginRight: '30px'  }}>
                        {selectedSequence && !isLoadingSequences && !isLoadingSelectedSequence && <Quality20QuestionSection sequenceView={sequenceData?.view} question={currentQuestion} />}
                        {!lowResolutionOn && <Quality20KeyboardShortcutsLegend/> }
                    </div>

                    <br/>
                    {/* clips section */}
                    {!allDone && (
                        <>
                        <div style={{ height: lowResolutionOn? '55vh' : '67vh', width: '100%', overflow: 'auto', marginLeft: '10px'}}>
                            {isLoadingSelectedSequence || isLoadingSequences ? (
                                <div align="center">
                                    <CircularProgress />
                                </div>
                            ) : sequenceData !== null && sequenceData.clips !== null ? (
                                <Quality20Clips
                                    selectedSequence={selectedSequence}
                                    clips={sequenceData.clips}
                                    currentQuestionName={currentQuestion.name}
                                    sequenceAnswers={sequenceAnswers}
                                    setSequenceAnswers={setSequenceAnswers}
                                    markCompleted={handleDone}
                                    lowResolutionOn={lowResolutionOn}
                                />
                            ) : null}
                        </div>
                        <br/>
                        <Container sx={{ display: 'flex',width: '100%', justifyContent: 'space-between' }}>
                            <Button ref={doneButton} onClick={handleDone} disabled={!canSubmit} variant="contained" style={{ height: '30px' }}>
                                Save Answers & Move to Next
                            </Button>
                            <Button onClick={changeLowResolution} variant="contained" style={{ height: '30px' }}>
                                Low Resolution: {lowResolutionOn? "On" : "Off"}
                            </Button>
                        </Container>
                        <br/>
                    </>
                    )}
                    {allDone && (
                        <Container sx={{ width: '100%', justifyContent: 'center', alignItems: 'center' }}>
                            <iframe style={{ PointerEvent: 'unset!important', width: '480px', height: '406px' }} src="https://giphy.com/embed/BrFuiMe3YUt3laSeEO" allowFullScreen></iframe>
                        </Container>
                    )}
                </>
            )}
        </>
    )
}
