import React, { useEffect, useRef, useState } from 'react'
import { Container, Grid } from '@mui/material'
import Quality20Clip from './Quality20Clip'
import useKeyboardShortcut from 'use-keyboard-shortcut'

function Quality20Clips({ selectedSequence, clips, currentQuestionName, sequenceAnswers, setSequenceAnswers, markCompleted, lowResolutionOn }) {
    const { flushHeldKeysV } = useKeyboardShortcut(['v'], (shortcutKeys) => {
        markCurrentClip('true')
    })
    const { flushHeldKeysX } = useKeyboardShortcut(['x'], (shortcutKeys) => {
        markCurrentClip('false')
    })
    const { flushHeldKeysU } = useKeyboardShortcut(['u'], (shortcutKeys) => {
        markCurrentClip('other')
    })
    const { flushHeldKeysD } = useKeyboardShortcut(['d'], (shortcutKeys) => {
        markCompleted()
    })
    const { flushHeldArrowRight } = useKeyboardShortcut(['ArrowRight'], (shortcutKeys) => {
        setNextIndex()
    })
    const { flushHeldArrowLeft } = useKeyboardShortcut(['ArrowLeft'], (shortcutKeys) => {
        setPreviousIndex()
    })

    const clipRefs = useRef([])
    const [currentClipIndex, setCurrentClipIndex] = useState(0)
    
    function setNextIndex() {
        setCurrentClipIndex((currentClipIndex + 1) % Object.keys(clips).length)
    }

    function setPreviousIndex() {
        setCurrentClipIndex((prev) => ((prev - 1) < 0 ? (Object.keys(clips).length - 1) : (prev - 1)))
    }

    function clipClicked(clipId) {
        if (!sequenceAnswers[currentQuestionName]) {
            setSequenceAnswers((prev) => ({ ...prev, [currentQuestionName]: { [clipId]: 'true' } }))
        } else {
            setSequenceAnswers((prev) => ({ ...prev, [currentQuestionName]: { ...sequenceAnswers[currentQuestionName], [clipId]: getNextMarking(sequenceAnswers[currentQuestionName][clipId]) } }))
        }
        setCurrentClipIndex(Object.keys(clips).indexOf(clipId))
    }

    function getNextMarking(marking) {
        if (marking === 'true') return 'false'
        if (marking === 'false') return 'other'
        return 'true'
    }

    function markCurrentClip(mark) {
        const clipId = Object.keys(clips)[currentClipIndex]
        if (!sequenceAnswers[currentQuestionName]) {
            setSequenceAnswers((prev) => ({ ...prev, [currentQuestionName]: { [clipId]: mark } }))
        } else {
            setSequenceAnswers((prev) => ({ ...prev, [currentQuestionName]: { ...sequenceAnswers[currentQuestionName], [clipId]: mark } }))
        }
        setNextIndex()
    }

    useEffect(() => {
        setCurrentClipIndex(0)
    }, [currentQuestionName])

    useEffect(() => {
        clipRefs.current[currentClipIndex]?.scrollIntoView({ behavior: 'smooth' })
    }, [currentClipIndex])

    useEffect(() => {
        clipRefs.current = Array(clips.length)
    }, [clips])

    return (
        <Grid container height="100%" width="100%" spacing={2} >
            {Object.keys(clips).map((clipId, index) => {
                return (
                    <Grid item xs={12} sm={6} md={lowResolutionOn? 3 : 2} key={clipId} ref={(el) => (clipRefs.current[index] = el)}>
                        <Quality20Clip
                            clipUrl={clips[clipId]}
                            clipId={clipId}
                            onClipClick={clipClicked}
                            marked={sequenceAnswers[currentQuestionName]?.[clipId]}
                            currentClipIndex={currentClipIndex}
                            clipIndex={index}
                        />
                    </Grid>
                )
            })}
        </Grid>
    )
}
export default React.memo(Quality20Clips)
